import { createEventDate } from '@/lib/utils/createEventDate';
import { fmtCategoryNames } from '@/lib/utils/format';
import Card from '../Card';
import Icon from '../Icon';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import { EntryCardProps } from './EntryCardProps';

export type FeaturedCardProps = EntryCardProps<'article'>;

export const FeaturedCard = ({ item, ...props }: FeaturedCardProps) => {
  const {
    uri: href,
    title,
    summary: description,
    pageTitle,
    postDate,
    contentTypeCategorySingle,
    entryImage: image,
    eventStartDate,
    eventEndDate,
  } = item;

  const contentTypeName = fmtCategoryNames(contentTypeCategorySingle);

  const { eventDate, isPast } = createEventDate(eventStartDate, eventEndDate);

  return (
    <Card
      elevation={false}
      paper
      {...props}
      item={{
        href,
        title: pageTitle || title,
        meta: [contentTypeName, postDate].filter(Boolean),
        description,
        image,
      }}>
      <Card.Image ratio="landscape" transform="fullFit" />
      <Card.Body cx={{ pT: 'md', rowGap: 'xs' }}>
        <Card.Meta />
        <Card.Title variant="h5" as="span" />
        {eventDate && (
          <Flex direction="row" alignItems="center" spacing="3xs">
            <Icon name={isPast ? 'video' : 'calendar'} />
            <Txt variant="small">{eventDate}</Txt>
          </Flex>
        )}
        <Card.Cta variant="text" size="small" endIcon="arrowRight" cx={{ fontSize: 'medium' }} />
      </Card.Body>
    </Card>
  );
};
