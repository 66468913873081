import { gql } from '__generated__';

/**
 * Retrieve all URIs, optionally filtered by section, order and/or type
 */
export const ALL_URIS_QUERY = gql(`
  query allUris(
    $site: [String] = "default"
    $preferSites: [QueryArgument] = ["default"]
    $unique: Boolean
    $section: [String]
    $type: [String]
    $orderBy: String
    $limit: Int = 1000
  ) {
    entries(
      site: $site
      preferSites: $preferSites
      unique: $unique
      section: $section
      type: $type
      orderBy: $orderBy
      limit: $limit) {
      id
      uri
      slug
    }
  }
`);

/**
 * Retrieve a single entry
 */
export const ENTRY_BY_URI_QUERY = gql(`
  query entryByUri(
    $site: [String] = "default"
    $preferSites: [QueryArgument] = ["default"]
    $unique: Boolean
    $uri: [String]
    $slug: [String]
    $section: [String]
    $orderBy: String
    # $seoEnvironment: SeomaticEnvironment
  ) {
    entry(
      site: $site
      preferSites: $preferSites
      unique: $unique
      uri: $uri
      slug: $slug
      section: $section
      orderBy: $orderBy
    ) {
      ...entries
    }
  }
`);

export const REDIRECT_QUERY = gql(`
  query redirect($uri: String) {
    redirect: retour(uri: $uri) {
      redirectDestUrl
    }
  }
`);

/**
 * Retrieve a many entries by section
 */
export const ENTRIES_BY_TYPE_QUERY = gql(`
  query contentByType(
    $site: [String] = "default"
    $preferSites: [QueryArgument] = ["default"]
    $unique: Boolean
    $section: [String]
    $type: [String]
    $orderBy: String
    $limit: Int
    $articleCategoryId: [QueryArgument]
  ) {
    entries(
      private: false
      site: $site
      preferSites: $preferSites
      unique: $unique
      section: $section
      type: $type
      orderBy: $orderBy
      limit: $limit
      contentTypeCategorySingle: $articleCategoryId) {
      ...entries
    }
  }
`);

/**
 * Check if an entry exists by URI
 * slug, or ID.
 */
export const ENTRY_EXISTS_QUERY = gql(`
  query entryExists(
    $site: [String] = "default"
    $preferSites: [QueryArgument] = ["default"]
    $unique: Boolean
    $uri: [String],
    $slug: [String],
    $id: [QueryArgument]
  ) {
    entry(
      uri: $uri,
      id: $id,
      slug: $slug,
      limit: 1
      site: $site
      preferSites: $preferSites
      unique: $unique
    ) {
      __typename
      id
      uri
      url
    }
  }
`);

/**
 * Retrieve all related entries for a given entry
 */
export const RELATED_ENTRIES_QUERY = gql(`
  query relatedEntries(
    $site: [String] = "default"
    $preferSites: [QueryArgument] = ["default"]
    $unique: Boolean
    $exclude: QueryArgument
    $section: [String]
    $categoryId: [CategoryRelationCriteriaInput]
    $entryId: [QueryArgument]
    $parentId: Int
    $orderBy: String
    $limit: Int = 4
  ) {
    entries(
      private: false
      site: $site
      preferSites: $preferSites
      unique: $unique
      limit: $limit
      id: ["not", $exclude]
      relatedTo: $entryId
      relatedToCategories: $categoryId
      section: $section
      orderBy: $orderBy
      descendantOf: $parentId
    ) {
      ...entryCards
    }
  }
`);

export const ENTRY_SEO_QUERY = gql(`
  query entrySeo(
    $site: [String] = "default"
    $preferSites: [QueryArgument] = ["default"]
    $unique: Boolean
    $uri: [String]
    $slug: [String]
    $id: [QueryArgument]
    $section: [String]
    $type: [String]
  ) {
    entry(
      site: $site
      preferSites: $preferSites
      unique: $unique
      uri: $uri
      slug: $slug
      id: $id
      section: $section
      type: $type
    ) {
      __typename
      id
      ...entrySeo
    }
  }
`);
